import { Component, OnInit } from '@angular/core';

import { SpaL1Service } from './services/spa-l1.service';
import { ObeL1UiService } from '@libraries/obe-l1-ui/obe-l1-ui';
import { AppConfig } from './shared/services/app.config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public envConfig: any;
  title = 'spa-l1';

  constructor(
    private l1service: ObeL1UiService, private service: SpaL1Service
    ) {}

  ngOnInit(): void {
    this.setLoginAuthorityToken();
    this.l1service.setConfig(AppConfig.config);
  }

  setLoginAuthorityToken() {
    this.service.setJwtToken().subscribe();
  }

}
