import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppConfig } from "src/app/shared/services/app.config";

@Injectable({
    providedIn: 'root'
})
export class ApiSpaL1Service {
    constructor(protected http: HttpClient) { }

    setLoginAuthority() {
        // let headers = new HttpHeaders().set('X-AN-WebService-IdentityKey', AppConfig.config['loginConfig'].identityKey)
        // .append('Content-Type','application/x-www-form-urlencoded');
        // const api = AppConfig.config['loginConfig'].url;
        // let body = {
        //     user: AppConfig.config['loginConfig'].user,
        //     password: AppConfig.config['loginConfig'].password
        // }
        // let params = new HttpParams();
        // params = params.set('user', AppConfig.config['loginConfig'].user);
        // params = params.set('password', AppConfig.config['loginConfig'].password);
        // return this.http.post(api + 'proxy/loginAuthority', params, {headers});

        let url = 'https://obe-nonprod-dev.apigee.net/api/obe/jwt-generator/v1?subject=B2CCZ&language=en&msisdn=32499496777';
        return this.http.get(url);
    }
}